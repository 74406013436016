import React from "react";

import Layout from "../components/layout";
import SEO from "../components/seo";
import cam from "../images/cam.svg";

const SuccessPage = () => (
  <Layout>
    <SEO
      keywords={[`punkrockvideos`, `punk`, `records`, `skatebaords`]}
      title="Order Complete"
    />

    <section className="flex flex-col items-center md:flex-row">
      <div className="md:w-2/3 md:mr-8">
        <h1 className="font-bold text-x1">Thanks for stopping by.</h1>
        <p className="text-x2">
          Your order is on its way thanks. The next email you receive will
          included the tracking information.
        </p>
      </div>

      <figure className="w-2/3 md:w-1/3">
        <img alt="logo" src={cam} />
      </figure>
    </section>
  </Layout>
);

export default SuccessPage;
